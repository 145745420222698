body {
  background-color: #1F1F1F;
  color: white
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.gamespace {
  width: 100%;
  max-width: 500px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

#logo {
  width: 38px;
  height: 38px;
}

.game-board {
  background-color: #1F1F1F;
  display: grid;
  grid-template-rows: repeat(3, minmax(0,1fr));
  width: 100%;
}

.instructions {
  width: 100%;
}

.header-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#max-score {
  color:gray;
  font-size: smaller;
}

.row {
  display: flex;
}

.cell {
  flex: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  border: 1px solid grey;
  padding: 5px;
}

.row:first-of-type .cell:first-of-type {
  border-top-left-radius: 3px;
}

.row:first-of-type .cell:last-of-type {
  border-top-right-radius: 3px;
}

.row:last-of-type .cell:first-of-type {
  border-bottom-left-radius: 3px;
}

.row:last-of-type .cell:last-of-type {
  border-bottom-right-radius: 3px;
}

button {
  background-color: lightgray;
  font-size: 1.17em;
  margin: 0.5em 0;
  padding: 0.25em 0.5em;
  border: 0;
  border-radius: 3px;
}

.button-hover:hover {
  background-color: grey;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DDDBDD;
  opacity: 0.2;
  z-index: 1;
}

.loading {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

.cell-answer-image-container {
  width: 50px;
  height: 50px;
  position: relative;
}

.cell-answer-image {
  position: absolute;
  top: 0;
  left: 0;
}

.cell-answer-text {
  max-width: 100%;
  word-break: break-all;
}

.cell-image {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 3px;
}

.skeleton-header-text {
  width: 50px;
  height: 1em;
  border-radius: 3px;
}

.skeleton {
  display: inline-block;
  background: #DDDBDD;
  position: relative;
  overflow: hidden;
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(90deg,rgba(255,255,255, 0) 0%,rgba(255,255,255, 0.2) 20%,rgba(255,255,255, 0.5) 60%,rgba(255,255,255,0) 0%);
  animation: shimmer 3s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cell-input, input[type="text"], textarea {
  border: 0;
  background-color : #292929;
  color: white
}

.cell-input {
  width: 100%;
  box-sizing: border-box;
}

.cell-input, .loadingContainer {
  grid-area: 1 / 1;
}

.input-shake {
  animation: shake 0.5s;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  white-space:pre-line;  
  position:relative;
  top:-7px;
  
}
::-moz-placeholder { /* Firefox 19+ */
     white-space:pre-line;  
  position:relative;
  top:-7px;
}
:-ms-input-placeholder { /* IE 10+ */
    white-space:pre-line;  
  position:relative;
  top:-7px;
}
:-moz-placeholder { /* Firefox 18- */
     white-space:pre-line;  
  position:relative;
  top:-7px;
}

@media only screen and (max-width: 500px) {
  .gamespace {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 350px) {
  #logo {
    display: none;
  }
}